import Brightness1Icon from '@mui/icons-material/Brightness1';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useState} from "react";

const AnimatedCheckMark = ({error}) => {

	const [removeCircle, setRemoveCircle] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setRemoveCircle(true);
		}, 300)
	}, [])

	return <div className="flex justify-center items-center">
		<div className="relative">
			<Brightness1Icon
				style={{
					color: (error) ? "#EE0000" : "#038768" ,
					width: "3.6em",
					height: "3.6em"
				}}
			/>
		</div>
		<div className="absolute">
			<Brightness1Icon
				style={{
					color: "#FFFFFF",
					width: "3.6em",
					height: "3.6em",
					opacity: (removeCircle) ? 0 : 1,
				}}
				className="animate-fill-circle"
			/>
		</div>
		<div className={(removeCircle) ? "absolute animate-enlarge-check" : "absolute opacity-0"}>
			{
				(error) ?
					<CloseIcon
						style={{
							color: "#ffffff",
							width: "3em",
							height: "3em"
						}}
					/>:
					<CheckIcon
						style={{
							color: "#ffffff",
							width: "3em",
							height: "3em"
						}}
					/>
			}
		</div>
	</div>
}

export default AnimatedCheckMark;