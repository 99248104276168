import KiwiLogo from "./KiwiLogo";

const Title = () => {
	return <div className="text-6xl flex justify-center">
		<div>
			<div className="text-xl text-center font-dm-sans-regular text-[#9191A1]">
				Notifications
			</div>
			<div className="flex">
				<div className="mr-4">
					<KiwiLogo fill={"#000000"}/>
				</div>
				<div className="font-dm-sans-bold">
					kiwichat
				</div>
			</div>
		</div>
	</div>
}

export default Title;