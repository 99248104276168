import './App.css';
import {useQuery} from "react-query";
import Title from "./components/Title";
import useParams from "./hooks/useParams";
import {useEffect, useState} from "react";
import Content from "./components/page/Content";
import NotFound from "./components/page/NotFound";
import config from './config.json';
import axios from "axios";

function App() {

  const invite = useParams();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function unsubscribeFromMailingList () {
    const env = config.env;

    const baseUrl = `acc-mgmt-api.kiwichat.com`;
    const envSpecificUrl = `https://${env !== 'prod' ? `${env}-` : ''}${baseUrl}`;
    const data = (invite) ? await axios.post(envSpecificUrl, {
      uuid: invite,
    }) : undefined;

    setIsLoading(false);
    setError((data?.data?.statusCode >= 400))

    return data;
  }

  const { status } = useQuery('unsubscribe', unsubscribeFromMailingList);

  useEffect(() => {
    console.log("Unsubscribe process finished...");
  }, [status]);

  return <div className="flex justify-center items-center h-screen w-screen">
    <div className="relative bottom-28">
      <Title />
      {
          (invite) ?
              <Content isLoading={isLoading} error={error}/> :
              <NotFound/>
      }
    </div>
  </div>
}

export default App;
