import Loader from "./visual-notifier/Loader";
import AnimatedCheckMark from "./visual-notifier/AnimatedCheckMark";
import {useEffect, useState} from "react";

const NotifierIcon = ({isLoading, error}) => {

	const [switchIcon, setSwitchIcon] = useState(false);

	useEffect(() => {
		if(!isLoading){
			setTimeout(() => {
				setSwitchIcon(true)
			}, 300);
		}
	}, [isLoading]);

	return <div className="flex justify-center items-center">
		<div className="relative">
			<Loader isLoading={isLoading} error={error}/>
		</div>
		<div className="absolute">
			{(switchIcon) && <AnimatedCheckMark error={error}/>}
		</div>
	</div>
}

export default NotifierIcon;