const UnsubscribeMessage = () => {
	return <div className="flex justify-center items-center animate-fade-confirmation-text-up">
		<div>
			<div className="text-3xl font-dm-sans-medium text-center">
				You're unsubscribed.
			</div>
			<div className="text-xl font-dm-sans-medium text-center mt-2 text-[#9191A1]">
				We won't be sending you anymore emails.
			</div>
			<div className="text-center mt-8 text-base font-dm-sans text-[#9191A1]">
				Unsubscribed by accident? Resubscribe.
			</div>
		</div>
	</div>
}

export default UnsubscribeMessage;