import NotifierIcon from "../NotifierIcon";
import Message from "../Message";

const Content = ({isLoading, error}) => {
	return <div>
		<div className="mt-8">
			<div className="flex justify-center items-center h-[6em]">
				<NotifierIcon isLoading={isLoading} error={error}/>
			</div>
			<div className="mt-8 h-[6em]">
				<Message isLoading={isLoading} error={error}/>
			</div>
		</div>
	</div>
}

export default Content;