const LoadingMessage = ({isLoading}) => {
	return <div className="text-xl font-dm-sans-medium text-center">
		<div
			className={
				(isLoading) ?
					"animate-fade-load-text-up" :
					"animate-fade-load-text-down opacity-0"
			}
		>
			Removing you from our mailing list...
		</div>
	</div>
}

export default LoadingMessage;