import EmailIcon from '@mui/icons-material/Email';
import {CircularProgress} from "@mui/material";

const Loader = ({isLoading, error}) => {

	const progressStyling = {
		width: "1.7em",
		height: "1.7em",
		color: (error) ? "#EE0000" : "#038768",
	}

	return <div className="flex justify-center items-center text-5xl">
		{
			(isLoading) ?
			<CircularProgress
				style={progressStyling}
			/> :
			<CircularProgress
				style={progressStyling}
				variant="determinate"
				value={100}
			/>
		}
		<div className={(!isLoading) ? "absolute animate-fade-email-icon-out": "absolute"}>
			<EmailIcon
				style={{
					width: "1.7em",
					height: "1.7em",
					bottom: "0.2em",
					position: "relative",
					color: (error) ? "#EE0000" : "#038768",
				}}
			/>
		</div>
	</div>
}

export default Loader;

