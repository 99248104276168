const NotFound = () => {
	return <div className="mt-16 h-[14em]">
		<div className="text-6xl font-dm-sans-bold text-center">
			<div className="flex justify-center items-center">
				<div className="text-[#038768]">4</div>
				<div>0</div>
				<div className="text-[#038768]">4</div>
			</div>
			<div className="text-2xl mt-6">
				Page Not Found
			</div>
		</div>
	</div>
}

export default NotFound;