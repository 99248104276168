import LoadingMessage from "./message/LoadingMessage";
import UnsubscribeMessage from "./message/UnsubscribeMessage";
import {useEffect, useState} from "react";
import InvalidMessage from "./message/InvalidMessage";

const Message = ({isLoading, error}) => {

	const [switchToUnsubscribe, setSwitchToUnsubscribe] = useState(false);

	useEffect(() => {
		if(!isLoading){
			setTimeout(() => {
				setSwitchToUnsubscribe(true)
			}, 400)
		}
	}, [isLoading])

	return <div>
		{
			(switchToUnsubscribe) ?
				(error) ? <InvalidMessage /> : <UnsubscribeMessage /> :
				<LoadingMessage isLoading={isLoading} />
		}
	</div>
}

export default Message;