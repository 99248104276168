const KiwiLogo = ({fill}) => {
	return <svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="relative w-[0.32em] h-[0.32em] top-[0.38em]"
		transform="scale(2)"
	>
		<path
			d="M13.6612 2.34812C12.153 0.840956 10.1202 0 8 0H0V7.99454C0 10.1133 0.852459
			12.1447 2.34973 13.641C3.84699 15.1481 5.87978 15.9891 8 16H16V7.99454C16 5.87577
			15.1585 3.84437 13.6612 2.34812ZM6.44809 11.7406C5.95628 11.5331 5.5082 11.2382 5.13661
			10.8669C4.76503 10.4956 4.45902 10.0478 4.2623 9.55631C4.05465 9.06485 3.95628 8.54061
			3.95628 8.00546C3.94536 5.76655 5.75956 3.92082 8 3.92082C8.53552 3.92082 9.06011 4.03003
		 	9.55191 4.22662C10.0437 4.43413 10.4918 4.72901 10.8634 5.10034C11.235 5.47167 11.541
		 	5.91945 11.7377 6.41092C11.9454 6.90239 12.0437 7.42662 12.0437
		 	7.96177V11.9918V12.0137V12.0464H8C7.46448 12.0464 6.93989 11.9372 6.44809 11.7406Z"
		  	fill={fill ?? "#038768"}
		/>
	</svg>

}

export default KiwiLogo;